import React from "react";
// import { DirectionalLightHelper } from "three";
// import { useHelper } from "@react-three/drei";

export const Lights = () => {
  //   const lightRef = useRef<any>();
  //   const lightRef2 = useRef<any>();
  //   const lightRef3 = useRef<any>();

  //   useHelper(lightRef, DirectionalLightHelper, 5, "red");
  //   useHelper(lightRef2, DirectionalLightHelper, 5, "green");
  //   useHelper(lightRef3, DirectionalLightHelper, 5, "blue");

  return (
    <>
      <hemisphereLight
        args={["#fff", "#333"]}
        intensity={1.2}
        position={[0, 300, 0]}
      />
      <hemisphereLight
        args={["#fff", "#333"]}
        intensity={1.2}
        position={[0, -300, 0]}
      />
      <directionalLight
        // ref={lightRef2}
        args={["#fff"]}
        intensity={1.2}
        position={[0, -500, 0]}
        // position={[75, 300, -75]}
      />
      <directionalLight
        // ref={lightRef}
        args={["#fff"]}
        intensity={1.2}
        position={[-75, 300, 75]}
      />
      <directionalLight
        // ref={lightRef3}
        args={["#fff"]}
        intensity={1.2}
        position={[75, 300, -75]}
      />
    </>
  );
};
