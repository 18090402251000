import React, { useState } from "react";
import { GlobalRefs } from "../store/refs";

import { Redirect, RouteProps } from "react-router-dom";
import ResetPassForm from "../components/ResetPassForm";
import UsernameForm from "../components/UsernameForm";
import { LogonFormWrapper } from "../components/LogonFormWrapper";

interface Props extends RouteProps {
  globalrefs: React.MutableRefObject<GlobalRefs>;
}
interface State {
  redirectToLogin: boolean;
  username: string | undefined;
  errorMessage: string | undefined;
}

export const ResetPassword: React.FC<Props> = ({ globalrefs }) => {
  const [state, setState] = useState<State>({
    redirectToLogin: false,
    username: undefined,
    errorMessage: undefined,
  });

  const completeReset = async ({
    code,
    newPassword,
  }: {
    code: string;
    newPassword: string;
  }) => {
    try {
      const resetResult = await globalrefs.current.auth.completeReset(
        state.username || "",
        code,
        newPassword
      );
      if (resetResult.success) {
        setState({
          redirectToLogin: true,
          username: state.username,
          errorMessage: undefined,
        });
      } else {
        setState({
          redirectToLogin: false,
          username: undefined,
          errorMessage:
            resetResult.errorMessage ||
            "Could not reset that users's password.",
        });
      }
    } catch (error: unknown) {
      console.error(
        "Could not reset with provided info",
        state.username,
        error
      );
      setState({
        redirectToLogin: false,
        username: undefined,
        errorMessage:
          error instanceof Error
            ? error.message
            : "Could not reset that users's password.",
      });
    }
  };

  const initiateReset = async ({ username }: { username: string }) => {
    try {
      const resetResult = await globalrefs.current.auth.resetPassword(username);
      if (resetResult.success) {
        setState({
          redirectToLogin: false,
          username: username,
          errorMessage: undefined,
        });
      } else {
        setState({
          redirectToLogin: false,
          username: undefined,
          errorMessage:
            resetResult.errorMessage ||
            "Could not reset that users's password.",
        });
      }
    } catch (error: unknown) {
      console.error("Could not reset user with that email", error);
      setState({
        redirectToLogin: false,
        username: undefined,
        errorMessage:
          error instanceof Error
            ? error.message
            : "Could not reset that users's password.",
      });
    }
  };

  const { redirectToLogin } = state;

  if (redirectToLogin) {
    return <Redirect to={"/"}></Redirect>;
  }

  const form =
    state.username === undefined ? (
      <UsernameForm
        submitCallback={(username) => initiateReset(username)}
        validationMessage={state.errorMessage}
      ></UsernameForm>
    ) : (
      <ResetPassForm
        submitCallback={(resetData) => completeReset(resetData)}
        validationMessage={state.errorMessage}
      ></ResetPassForm>
    );
  const header = state.username === undefined ? "Password Reset" : "";

  return <LogonFormWrapper component={form} header={header}></LogonFormWrapper>;
};
