import React, { useContext } from "react";
import { GlobalRefs } from "../store/refs";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { baseMeshMap } from "../data/maps";
import ContainerSkeletalCarrier from "../images/Container Skeletal Carrier.png";
import ReeferTruckTrailer from "../images/Reefer Truck Trailer.png";
import Truck from "../images/Truck.png";

interface Props {
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

export const BaseObjectSelector: React.FC<Props> = ({ globalrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { baseMeshObject } = store;

  const onClick = (key: string) => {
    dispatch({
      type: actionTypes.SET_MESHCONTROL,
      payload: { active: false, name: "", assembly: "", message: "" },
    });
    dispatch({ type: actionTypes.SET_BASEMESHOBJECT, payload: key });
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: "100%",
        }}
      >
        {Object.keys(baseMeshMap).map((key: string, i: number) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: key === baseMeshObject ? "pink" : "white",
                border: key === baseMeshObject ? "2px solid black" : "none",
                padding: 15,
              }}
              onClick={() => onClick(key)}
            >
              <img
                alt="alt"
                src={
                  key === "trailer"
                    ? ContainerSkeletalCarrier
                    : key === "reefer"
                    ? ReeferTruckTrailer
                    : Truck
                }
                style={{ width: "100%" }}
                title={baseMeshMap[key].displayName}
              ></img>
            </div>
          );
        })}
      </div>
    </div>
  );
};
