interface MeshMap {
  [assembly: string]: { assembly: string; message?: string };
}

interface BaseMeshMap {
  [baseObject: string]: { map: MeshMap; model: string; displayName: string };
}

interface AssemblyAttributes {
  [key: string]: { model: string; [key: string]: string };
}

export const truckMeshMap: MeshMap = {
  Cube: { assembly: "None" },
  Plane: { assembly: "None" },
  WheelLeft1: {
    assembly: "Wheel",
    message: "Front left wheel assembly",
  },
  WheelLeft2: {
    assembly: "Wheel",
    message: "2nd from front left wheel assembly",
  },
  WheelLeft3: {
    assembly: "Wheel",
    message: "3rd from front left wheel assembly",
  },
  WheelLeft4: {
    assembly: "Wheel",
    message: "2nd from back left wheel assembly",
  },
  WheelLeft5: {
    assembly: "Wheel",
    message: "back left wheel assembly",
  },
  WheelRight1: {
    assembly: "Wheel",
    message: "Front right wheel assembly",
  },
  WheelRight2: {
    assembly: "Wheel",
    message: "2nd from front right wheel assembly",
  },
  WheelRight3: {
    assembly: "Wheel",
    message: "3rd from front right wheel assembly",
  },
  WheelRight4: {
    assembly: "Wheel",
    message: "2nd from back right wheel assembly",
  },
  WheelRight5: {
    assembly: "Wheel",
    message: "Back right wheel assembly",
  },
  Cube001: { assembly: "None" },
  Cube002: { assembly: "Reefer", message: "Reefer" },
  Cube003: { assembly: "None" },
  Cylinder001: {
    assembly: "FuelTank",
    message: "Fuel tank assembly",
  },
  Plane001: { assembly: "None" },
  Cylinder002: { assembly: "None" },
};

export const reeferMeshMap: MeshMap = {
  "077-003": {
    assembly: "077-003",
    message: "Leg Assembly",
  },
  LeftFrontWheel: {
    assembly: "018-003",
    message: "Left Front Wheel",
  },
  LeftBackWheel: {
    assembly: "018-003",
    message: "Left Back Wheel",
  },
  RightFrontWheel: {
    assembly: "018-003",
    message: "Right Front Wheel",
  },
  RightBackWheel: {
    assembly: "018-003",
    message: "Right Back Wheel",
  },
};
export const trailerMeshMap: MeshMap = {
  Container: {
    assembly: "Container",
    message: "Container",
  },
  Landing_Gear: {
    assembly: "Landing_Gear",
    message: "Landing gear",
  },
  LeftWheel1: {
    assembly: "Wheel",
    message: "Front left wheel assembly",
  },
  LeftWheel2: {
    assembly: "Wheel",
    message: "Middle left wheel assembly",
  },
  LeftWheel3: {
    assembly: "Wheel",
    message: "Back left wheel assembly",
  },
  Kingpin: {
    assembly: "Kingpin",
    message: "Coupler Assembly - Upper",
  },
  RightWheel1: {
    assembly: "Wheel",
    message: "Front right wheel assembly",
  },
  RightWheel2: {
    assembly: "Wheel",
    message: "Middle right wheel assembly",
  },
  RightWheel3: {
    assembly: "Wheel",
    message: "Back right wheel assembly",
  },
};

export const baseMeshMap: BaseMeshMap = {
  truck: {
    map: truckMeshMap,
    model: "gltf/truck06.glb",
    displayName: "Truck",
  },
  reefer: {
    map: reeferMeshMap,
    model: "gltf/Vanguard/reefer.glb",
    displayName: "Reefer Truck Trailer",
  },
  trailer: {
    map: trailerMeshMap,
    model: "gltf/trailer.glb",
    displayName: "Container Skeletal Carrier",
  },
};

export const assemblyAttributes: AssemblyAttributes = {
  FuelTank: {
    model: "gltf/tank2.glb",
    Tank: "Fuel tank",
    RightSecure: "Right securing bracket",
    LeftSecure: "Left securing bracket",
  },
  Landing_Gear: {
    model: "gltf/landinggear.glb",
    Attacher1: "Attacher bar",
    Attacher2: "Attacher bar",
    Attacher3: "Attacher bar",
    Attacher4: "Attacher bar",
    Attacher5: "Attacher bar",
    Attacher6: "Attacher bar",
    Attacher7: "Attacher bar",
    Attacher8: "Attacher bar",
    Crank: "Crank pin",
    CrankHandle: "Crank handle",
    CrankLever: "Crank lever",
    LeftFootplate: "Footplate",
    LeftUpright: "Upright",
    RightFootplate: "Footplate",
    RightUpright: "Upright",
  },
  Kingpin: {
    model: "gltf/kingpin.glb",
    Kingpin: "King Pin - Trailer Coupler",
  },
  Wheel: {
    model: "gltf/tire3.glb",
    tire: "Tire",
    rim: "Rim",
  },
  Reefer: {
    model: "gltf/reefer.glb",
    Container: "Insulated Container",
    Latch: "Door Latch",
    LeftDoor: "Left Door",
    RightDoor: "Right Door",
    Torus: "Cargo",
  },
  Container: {
    model: "gltf/container.glb",
  },
  "077-003": {
    model: "gltf/Vanguard/landingGear.glb",
  },
  "018-003": {
    model: "gltf/Vanguard/wheels.glb",
  },
};
