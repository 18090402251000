import React, { useReducer, useMemo, createContext, useContext } from "react";
import { init_global, global_reducer } from "./global_reducer";
import { State, Action } from "./storetypes";
import { Canvas } from "@react-three/fiber";

export const StoreContext = createContext<
  (State | React.Dispatch<Action>)[] | undefined
>(undefined);

interface Props {
  children: any;
}

export const StoreProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(global_reducer, init_global);
  const store = useMemo(() => [state, dispatch], [state]);
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const ForwardCanvas: React.FC = ({ children }) => {
  const value = useContext(StoreContext);
  return (
    <Canvas>
      <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    </Canvas>
  );
};
