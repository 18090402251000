import React, { useContext, useEffect } from "react";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import explodeX from "../images/explodeXT.png";
import explodeY from "../images/explodeYT.png";
import explodeZ from "../images/explodeZT.png";
import explodedX from "../images/explodedXT.png";
import explodedY from "../images/explodedYT.png";
import explodedZ from "../images/explodedZT.png";
import explodeAll from "../images/explodeAllT.png";
import explodedAll from "../images/explodedAllT.png";

interface Props {
  parent: string;
}

export const Controls: React.FC<Props> = ({ parent }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { baseMeshObject, controls, meshControl } = store;

  const parentKey: string =
    parent === "base" ? baseMeshObject : meshControl.assembly;

  useEffect(() => {
    //init
    if (parentKey in controls.explode[parent]) {
      //do nothing
    } else {
      controls.explode[parent][parentKey] = {
        x: false,
        y: false,
        z: false,
        a: false,
      };
    }
  }, [parentKey, controls, parent]);

  const toggleExplode = (key: string) => {
    dispatch({
      type: actionTypes.SET_CONTROLS,
      payload: {
        ...controls,
        explode: {
          ...controls.explode,
          [parent]: {
            ...controls.explode[parent],
            [parentKey]: {
              ...controls.explode[parent][parentKey],
              [key]: !controls.explode[parent][parentKey][key],
            },
          },
        },
        selectedExplodeButton: {
          ...controls.selectedExplodeButton,
          button: { ...controls.selectedExplodeButton.button, [parent]: key },
          parent: parent,
        },
      },
    });
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          margin: 10,
        }}
      >
        <button
          className="explodebutton"
          onClick={() => toggleExplode("a")}
          disabled={
            controls.explode[parent][parentKey] &&
            (controls.explode[parent][parentKey].x ||
              controls.explode[parent][parentKey].y ||
              controls.explode[parent][parentKey].z)
          }
        >
          <img
            alt="explodeAll"
            src={
              controls.explode[parent][parentKey] &&
              controls.explode[parent][parentKey].a
                ? explodedAll
                : explodeAll
            }
            style={{ width: 40 }}
          ></img>
        </button>{" "}
        <button
          className="explodebutton"
          onClick={() => toggleExplode("x")}
          disabled={
            controls.explode[parent][parentKey] &&
            controls.explode[parent][parentKey].a
          }
        >
          <img
            alt="explodeX"
            src={
              controls.explode[parent][parentKey] &&
              controls.explode[parent][parentKey].x
                ? explodedX
                : explodeX
            }
            style={{ width: 40 }}
          ></img>
        </button>
        <button
          className="explodebutton"
          onClick={() => toggleExplode("z")}
          disabled={
            controls.explode[parent][parentKey] &&
            controls.explode[parent][parentKey].a
          }
        >
          <img
            alt="explodeZ"
            src={
              controls.explode[parent][parentKey] &&
              controls.explode[parent][parentKey].z
                ? explodedZ
                : explodeZ
            }
            style={{ width: 40 }}
          ></img>
        </button>
        <button
          className="explodebutton"
          onClick={() => toggleExplode("y")}
          disabled={
            controls.explode[parent][parentKey] &&
            controls.explode[parent][parentKey].a
          }
        >
          <img
            alt="explodeY"
            src={
              controls.explode[parent][parentKey] &&
              controls.explode[parent][parentKey].y
                ? explodedY
                : explodeY
            }
            style={{ width: 40 }}
          ></img>
        </button>
      </div>
    </div>
  );
};
