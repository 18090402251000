import React, { useContext, useState, useEffect } from "react";
import { GlobalRefs } from "../store/refs";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { Combined } from "../types/operationTypes";
import plus from "../images/plus.png";
import minus from "../images/minus.png";
import close from "../images/close.png";

interface Props {
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

interface Structured {
  [assembly: string]: {
    [source: string]: {
      [code: string]: { component: Combined; quantity: number };
    };
  };
}
interface Values {
  component: Combined;
  selections: { [source: string]: { quantity: number } };
}

export const OrderPanel: React.FC<Props> = ({ globalrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { order } = store;
  const [structuredOrder, setStructuredOrder] = useState<Structured>({});

  useEffect(() => {
    if (order) {
      const structured: Structured = {};
      Object.entries(order).forEach((entry: any) => {
        const values: Values = entry[1];
        const code = values.component.Component;
        const assembly = values.component.Assembly_Code;
        if (assembly in structured) {
          Object.entries(values.selections).forEach((selection: any) => {
            if (selection[0] in structured[assembly]) {
              if (code in structured[assembly][selection[0]]) {
                structured[assembly][selection[0]][code].quantity =
                  structured[assembly][selection[0]][code].quantity +
                  selection[1].quantity;
              } else {
                structured[assembly][selection[0]][code] = {
                  component: values.component,
                  quantity: selection[1].quantity,
                };
              }
            } else {
              structured[assembly][selection[0]] = {
                [code]: {
                  component: values.component,
                  quantity: selection[1].quantity,
                },
              };
            }
          });
        } else {
          structured[assembly] = {};
          Object.entries(values.selections).forEach((selection: any) => {
            structured[assembly][selection[0]] = {
              [code]: {
                component: values.component,
                quantity: selection[1].quantity,
              },
            };
          });
        }
      });
      setStructuredOrder(structured);
    }
  }, [order]);

  const increaseQuantity = (code: string, selected: string) => {
    const newOrder = JSON.parse(JSON.stringify(order));
    newOrder[code].selections[selected].quantity++;
    dispatch({ type: actionTypes.SET_ORDER, payload: newOrder });
  };
  const decreaseQuantity = (code: string, selected: string) => {
    if (order[code].selections[selected].quantity - 1 === 0) {
      deleteComponent(code, selected);
    } else {
      const newOrder = JSON.parse(JSON.stringify(order));
      newOrder[code].selections[selected].quantity--;
      dispatch({ type: actionTypes.SET_ORDER, payload: newOrder });
    }
  };
  const deleteComponent = (code: string, selected: string) => {
    const newOrder = JSON.parse(JSON.stringify(order));
    delete newOrder[code].selections[selected];
    if (Object.keys(newOrder[code].selections).length === 0) {
      delete newOrder[code];
    }
    dispatch({ type: actionTypes.SET_ORDER, payload: newOrder });
  };
  const onClear = () => {
    dispatch({ type: actionTypes.SET_ORDER, payload: {} });
  };
  const onExit = () => {
    globalrefs.current.parentMesh = { name: "", assembly: "" };

    dispatch({
      type: actionTypes.SET_MESHCONTROL,
      payload: { active: false, name: "", assembly: "", message: "" },
    });
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          margin: 15,
        }}
      >
        <button className="button" onClick={() => onExit()}>
          Confirm Order
        </button>
        <button className="button" onClick={() => onClear()}>
          Clear
        </button>
        <button className="button" onClick={() => onExit()}>
          Exit
        </button>
      </div>
      <div>
        {Object.keys(structuredOrder).map((assembly: string, b: number) => {
          return (
            <div key={b}>
              {Object.keys(structuredOrder[assembly]).map(
                (source: string, i: number) => {
                  return (
                    <div key={i}>
                      <div className="assembly">{source}</div>
                      <table key={"t" + i}>
                        <tbody>
                          {Object.entries(
                            structuredOrder[assembly][source]
                          ).map((entry: any, k: number) => {
                            const code = entry[0];
                            const values: {
                              component: Combined;
                              quantity: number;
                            } = entry[1];
                            return (
                              <tr key={k}>
                                <td style={{ width: 200 }}>{code}</td>
                                <td>Quantity:</td>
                                <td style={{ width: 20 }}>{values.quantity}</td>
                                <td>
                                  <img
                                    title="Increase Quantity"
                                    alt="plus"
                                    src={plus}
                                    style={{ width: 16 }}
                                    onClick={() =>
                                      increaseQuantity(
                                        values.component.code,
                                        source
                                      )
                                    }
                                  ></img>
                                </td>
                                <td>
                                  <img
                                    title="Decrease Quantity"
                                    alt="minus"
                                    src={minus}
                                    style={{ width: 16 }}
                                    onClick={() =>
                                      decreaseQuantity(
                                        values.component.code,
                                        source
                                      )
                                    }
                                  ></img>
                                </td>
                                <td>
                                  <img
                                    title="Delete Component"
                                    alt="delete"
                                    src={close}
                                    style={{ width: 16 }}
                                    onClick={() =>
                                      deleteComponent(
                                        values.component.code,
                                        source
                                      )
                                    }
                                  ></img>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
