import React, { useContext } from "react";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { GlobalRefs } from "../store/refs";
import { Combined } from "../types/operationTypes";
import { writeComponentMapping } from "../api/Scirebug";

interface Props {
  component: Combined;
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

export const CodeCard: React.FC<Props> = ({ component, globalrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { admin, baseMeshObject, mode, order } = store;

  const onClick = () => {
    if (mode === "Admin") {
      let components =
        admin.selected in admin.components
          ? [...admin.components[admin.selected]]
          : [];

      const index = components.indexOf(component.code);
      if (index === -1) {
        components.push(component.code);
      } else {
        components.splice(index, 1);
      }
      dispatch({
        type: actionTypes.SET_ADMIN,
        payload: {
          ...admin,
          components: { ...admin.components, [admin.selected]: components },
        },
      });
      writeComponentMapping(
        globalrefs.current.auth,
        baseMeshObject,
        admin.selected,
        components
      );
      return;
    }
    const newOrder = JSON.parse(JSON.stringify(order));
    const selected = globalrefs.current.parentMesh.name;

    if (component.code in newOrder) {
      if (selected in newOrder[component.code].selections) {
        newOrder[component.code].selections[selected].quantity++;
      } else {
        newOrder[component.code].selections[selected] = {
          base: baseMeshObject,
          selectedMesh: admin.selected,
          quantity: 1,
        };
      }
    } else {
      newOrder[component.code] = {
        component: component,
        selections: {
          [selected]: {
            base: baseMeshObject,
            selectedMesh: admin.selected,
            quantity: 1,
          },
        },
      };
    }
    dispatch({ type: actionTypes.SET_ORDER, payload: newOrder });
  };

  const getBackgroundColor = () => {
    return admin.selected in admin.components &&
      admin.components[admin.selected].indexOf(component["code"]) !== -1
      ? mode === "Admin"
        ? globalrefs.current.defaultColours["admin"]
        : globalrefs.current.defaultColours["candidate"]
      : "#282a35";
  };

  return component ? (
    <div
      style={{
        // width: "100%",
        color: "white",
        backgroundColor: getBackgroundColor(),
        fontSize: 10,
        borderRadius: 4,
        marginTop: 2,
        padding: 4,
      }}
      onClick={() => onClick()}
    >
      {component["Component"]}
    </div>
  ) : null;
};
