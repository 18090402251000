import React, { useRef, useEffect, useCallback, useContext } from "react";
import { useLoader, useThree, useFrame } from "@react-three/fiber";
import { GlobalRefs } from "../store/refs";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { baseMeshMap } from "../data/maps";

interface Props {
  modelPath: string;
  scale: number;
  name: string;
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

const GltfModel: React.FC<Props> = ({ modelPath, scale, name, globalrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { baseMeshObject, baseGltf, controls, order, meshControl } = store;

  const gltfx: GLTF = useLoader(GLTFLoader, modelPath);

  useEffect(() => {
    if (gltfx) {
      dispatch({ type: actionTypes.SET_BASEGLTF, payload: gltfx });
    }
  }, [dispatch, gltfx]);

  const orderedAssemblies = useCallback(() => {
    const x: { [key: string]: {} } = {};
    Object.keys(order).forEach((code: any) => {
      Object.keys(order[code].selections).forEach((source: string) => {
        x[source] = {};
      });
    });
    return x;
  }, [order]);

  const setColorParent = useCallback(() => {
    // return;
    if (baseGltf) {
      baseGltf.scene.traverse((node: any) => {
        if (!node.isMesh) return;
        if (meshControl.name === node.name) {
          clonedMaterial.current = node.material.clone();
          node.material = clonedMaterial.current;
          node.material.color.set(globalrefs.current.defaultColours["hover"]);
        } else if (node.name in orderedAssemblies()) {
          clonedMaterial.current = node.material.clone();
          node.material = clonedMaterial.current;
          node.material.color.set(globalrefs.current.defaultColours["click"]);
        } else {
          if ("truck" in globalrefs.current.originalMaterial) {
            node.material = globalrefs.current.originalMaterial["truck"];
          }
        }
      });
    }
  }, [globalrefs, meshControl, baseGltf, orderedAssemblies]);

  useEffect(() => {
    setColorParent();
  }, [order, globalrefs, meshControl, setColorParent]);

  const restorePosition = useCallback(
    (
      nodeName: string,
      clonedPosition: { [key: string]: number },
      key: string
    ) => {
      //restore position
      if (
        globalrefs.current.explodedOriginalPosition[baseMeshObject] &&
        globalrefs.current.explodedOriginalPosition[baseMeshObject][nodeName]
      ) {
        if (key === "a") {
          return {
            ...clonedPosition,
            x: globalrefs.current.explodedOriginalPosition[baseMeshObject][
              nodeName
            ].x,
            y: globalrefs.current.explodedOriginalPosition[baseMeshObject][
              nodeName
            ].y,
            z: globalrefs.current.explodedOriginalPosition[baseMeshObject][
              nodeName
            ].z,
          };
        } else {
          return {
            ...clonedPosition,
            [key]:
              globalrefs.current.explodedOriginalPosition[baseMeshObject][
                nodeName
              ][key],
          };
        }
      } else {
        return clonedPosition;
      }
    },
    [baseMeshObject, globalrefs]
  );

  const savePosition = useCallback(
    (nodeName: string, clonedPosition: { [key: string]: number }) => {
      //save position
      if (globalrefs.current.explodedOriginalPosition[baseMeshObject]) {
        if (
          globalrefs.current.explodedOriginalPosition[baseMeshObject][nodeName]
        ) {
          //do nothing
        } else {
          globalrefs.current.explodedOriginalPosition[baseMeshObject][
            nodeName
          ] = { ...clonedPosition };
        }
      } else {
        globalrefs.current.explodedOriginalPosition[baseMeshObject] = {};
        globalrefs.current.explodedOriginalPosition[baseMeshObject][nodeName] =
          {
            ...clonedPosition,
          };
      }
    },
    [baseMeshObject, globalrefs]
  );

  useEffect(() => {
    if (
      baseGltf &&
      controls &&
      controls.selectedExplodeButton.parent === "base"
    ) {
      baseGltf.scene.traverse((node: any) => {
        if (!node.isMesh) return;
        const pos = controls.selectedExplodeButton.button.base;
        const clonedPosition: { [key: string]: number } = node.position.clone();
        if (controls.explode.base[baseMeshObject][pos]) {
          savePosition(node.name, clonedPosition);

          node.position.set(
            ...Object.values(
              pos === "a"
                ? {
                    ...clonedPosition,
                    x: clonedPosition.x * 1.5,
                    y: clonedPosition.y * 1.5,
                    z: clonedPosition.z * 1.5,
                  }
                : {
                    ...clonedPosition,
                    [pos]: clonedPosition[pos] * 1.5,
                  }
            )
          );
        } else {
          //restore position
          const newPosition = restorePosition(node.name, clonedPosition, pos);
          node.position.set(...Object.values(newPosition));
        }
      });
    }
  }, [
    baseGltf,
    baseMeshObject,
    controls,
    restorePosition,
    savePosition,
    globalrefs,
  ]);

  const clonedMaterial = useRef();
  const intersected = useRef<{ hover: string; click: string }>({
    hover: "",
    click: "",
  });

  const inspectIn = (e: any) => {
    intersected.current.hover = e.intersections[0].object.name;
    if (baseGltf) {
      baseGltf.scene.traverse((node: any) => {
        if (!node.isMesh) return;
        if (node.name === intersected.current.hover) {
          clonedMaterial.current = node.material.clone();
          if ("truck" in globalrefs.current.originalMaterial) {
            //do nothing
          } else {
            globalrefs.current.originalMaterial["truck"] =
              node.material.clone();
          }

          node.material = clonedMaterial.current;
          node.material.color.set(globalrefs.current.defaultColours["hover"]);
        }
      });
    }
  };

  const inspectOut = (e: any) => {
    setColorParent();
  };

  const onClick = (e: any) => {
    intersected.current.click = e.intersections[0].object.name;
    if (
      baseMeshMap[baseMeshObject].map[intersected.current.click] &&
      baseMeshMap[baseMeshObject].map[intersected.current.click].assembly in
        globalrefs.current.cameraPosition.assembly
    ) {
      globalrefs.current.parentMesh.name = intersected.current.click;
      globalrefs.current.parentMesh.assembly =
        baseMeshMap[baseMeshObject].map[intersected.current.click].assembly;

      dispatch({
        type: actionTypes.SET_MESHCONTROL,
        payload: {
          active: true,
          name: intersected.current.click,
          assembly:
            baseMeshMap[baseMeshObject].map[intersected.current.click].assembly,
          message:
            baseMeshMap[baseMeshObject].map[intersected.current.click].message,
        },
      });
    } else {
      dispatch({
        type: actionTypes.SET_MESHCONTROL,
        payload: { active: false, name: "", assembly: "", message: "" },
      });
    }
  };

  useThree(({ camera }) => {
    camera.position.y =
      globalrefs.current.cameraPosition.baseModel[baseMeshObject].y;
    camera.position.x =
      globalrefs.current.cameraPosition.baseModel[baseMeshObject].x;
    camera.position.z =
      globalrefs.current.cameraPosition.baseModel[baseMeshObject].z;
  });
  useFrame(({ camera, mouse }) => {
    globalrefs.current.cameraPosition.baseModel[baseMeshObject] = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    };
    // console.log(camera.position);
  });
  if (baseGltf) {
    return (
      <primitive
        object={baseGltf.scene}
        scale={scale}
        onPointerOver={(event: any) => inspectIn(event)}
        onPointerOut={(event: any) => inspectOut(event)}
        onClick={(event: any) => onClick(event)}
      />
    );
  } else return null;
};

export default GltfModel;
