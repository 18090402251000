import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { Home } from "./pages/Home";
import { GlobalRefs, GlobalRefsDefault } from "./store/refs";
import Signin from "./pages/Signin";
import { ResetPassword } from "./pages/ResetPassword";

const App: React.FC = (props: RouteProps) => {
  const globalrefs = useRef<GlobalRefs>(GlobalRefsDefault);

  const checkAuth = async () => {
    const isAuthed = await globalrefs.current.auth.checkAuth();
    setIsLogged(isAuthed);
  };

  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);

  const login = useCallback(
    ({ username, password }: { username: string; password: string }) => {
      globalrefs.current.auth.login(username, password).then((loginStatus) => {
        if (loginStatus.status === "success") {
          checkAuth();
          window.sessionStorage.setItem("Scirebug-currentUser", username);
          return;
        }
      });
    },
    []
  );
  //////

  useEffect(() => {
    // checkAuth();
    login({
      username: "bruce.edwards@scirebug.com",
      password: "Gabr1ella&AeronE",
    });
  }, [login]);

  return isLogged === undefined ? null : (
    <Switch>
      <RedirectIfTrueRoute
        condition={isLogged}
        redirectLocation="/"
        path="/login"
      >
        <Signin
          globalrefs={globalrefs}
          validateAuth={() => checkAuth()}
        ></Signin>
      </RedirectIfTrueRoute>
      <Route path="/reset-password">
        <ResetPassword globalrefs={globalrefs}></ResetPassword>
      </Route>
      <RedirectIfTrueRoute
        condition={!isLogged}
        redirectLocation="/login"
        path="/home"
      >
        <Home globalrefs={globalrefs}></Home>
      </RedirectIfTrueRoute>
      <RedirectIfTrueRoute
        condition={!isLogged}
        redirectLocation="/login"
        path="/"
      >
        <Redirect to="/home"></Redirect>
      </RedirectIfTrueRoute>
    </Switch>
  );
};

function RedirectIfTrueRoute({
  condition,
  redirectLocation,
  children,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={() => (condition ? <Redirect to={redirectLocation} /> : children)}
    />
  );
}

const RoutedApp = () => (
  <Router>
    <App></App>
  </Router>
);

export default RoutedApp;
