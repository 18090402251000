import { State, Action, actionTypes } from "./storetypes";
import { baseMeshMap } from "../data/maps";

const defaultBaseMeshObject = Object.keys(baseMeshMap)[1];

export const init_global: State = {
  controls: {
    explode: {
      base: {},
      assembly: {},
    },
    selectedExplodeButton: { button: { base: "", assembly: "" }, parent: "" },
  },
  order: {},
  meshControl: {
    active: false,
    name: "",
    assembly: "",
    message: "",
  },
  gltf: null,
  baseGltf: null,
  baseMeshObject: defaultBaseMeshObject,
  mode: "",
  admin: { selected: "", components: {} },
};

export function global_reducer(state: State, action: Action) {
  switch (action.type) {
    case actionTypes.SET_ADMIN: {
      return {
        ...state,
        admin: action.payload,
      };
    }
    case actionTypes.SET_BASEMESHOBJECT: {
      return {
        ...state,
        baseMeshObject: action.payload,
      };
    }
    case actionTypes.SET_CONTROLS: {
      return {
        ...state,
        controls: action.payload,
      };
    }
    case actionTypes.SET_GLTF: {
      return {
        ...state,
        gltf: action.payload,
      };
    }
    case actionTypes.SET_BASEGLTF: {
      return {
        ...state,
        baseGltf: action.payload,
      };
    }
    case actionTypes.SET_ORDER: {
      return {
        ...state,
        order: action.payload,
      };
    }
    case actionTypes.SET_MESHCONTROL: {
      return {
        ...state,
        meshControl: action.payload,
      };
    }
    case actionTypes.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
      };
    }

    default:
      return state;
  }
}
