import React, { useEffect, useContext, useState, useRef } from "react";
import { GlobalRefs } from "../store/refs";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { CodeCard } from "../components/CodeCard";
import { Combined } from "../types/operationTypes";
import { fetchCombined, fetchComponentMapping } from "../api/Scirebug";

interface Props {
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

export const Codes: React.FC<Props> = ({ globalrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { baseMeshObject, meshControl, admin } = store;

  const adminRef = useRef(admin);

  const [componentList, setComponentList] = useState<Combined[]>([]);

  useEffect(() => {
    adminRef.current = admin;
  }, [admin]);

  useEffect(() => {
    const fetchCombinedasync = async () => {
      const ret: any = await fetchCombined(
        globalrefs.current.auth,
        meshControl.assembly
      );
      setComponentList(ret.body);
    };
    if (meshControl.assembly) {
      fetchCombinedasync();
    }
  }, [globalrefs, meshControl.assembly, baseMeshObject]);

  useEffect(() => {
    const fetchComponentMappingasync = async () => {
      const ret: any = await fetchComponentMapping(
        globalrefs.current.auth,
        baseMeshObject,
        admin.selected
      );
      if (ret.body) {
        const newAdmin = adminRef.current;
        const newAdminComponents = {
          ...newAdmin.components,
          [admin.selected]: ret.body.components,
        };

        dispatch({
          type: actionTypes.SET_ADMIN,
          payload: { ...newAdmin, components: newAdminComponents },
        });
      }
    };
    if (admin.selected) {
      fetchComponentMappingasync();
    }
  }, [globalrefs, admin.selected, baseMeshObject, dispatch, adminRef]);

  const isShortlisted = (component: string) => {
    return admin.selected in admin.components &&
      admin.components[admin.selected].indexOf(component) !== -1
      ? false
      : true;
  };

  const sortFunction = (a: Combined, b: Combined) => {
    const sa = isShortlisted(a.code) ? "aa" + a.Component : a.Component;
    const sb = isShortlisted(b.code) ? "aa" + b.Component : b.Component;
    if (sa > sb) return 1;
    if (sa < sb) return -1;
    return 0;
  };

  return componentList.length > 0 ? (
    <div style={{ height: "100%" }}>
      {componentList
        .filter((item: Combined) => {
          return item.obsolete === "No";
        })
        .sort(sortFunction)
        .map((item: Combined, i) => {
          return <CodeCard key={i} component={item} globalrefs={globalrefs} />;
        })}
    </div>
  ) : null;
};
