import Auth from "../services/auth";

const apiUrl = `https://w0w5v8tuj9.execute-api.eu-west-2.amazonaws.com/prod`;

async function fetchDownloadUrl(
  token: string,
  filename: string,
  resource: string
): Promise<{ body: string }> {
  const response = await fetch(`${apiUrl}/${resource}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      file: filename,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}
export async function getFileDownloadUrl(
  auth: Auth,
  filepath: string,
  resource: string
): Promise<string> {
  try {
    const token = await auth.getToken();
    const downloadUrl = await fetchDownloadUrl(token, filepath, resource);
    return downloadUrl.body;
  } catch (error) {
    console.error("Could not get file URL", error);
    throw error;
  }
}

export interface Payload {
  [key: string]: string;
}

export async function writeCombined(
  auth: Auth,
  payload: Payload[]
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/combined`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      console.log(response.body.errorMessage);
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function writeComponentMapping(
  auth: Auth,
  base: string,
  selection: string,
  payload: string[]
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/componentmapping`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      base: base,
      selection: selection,
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      console.log(response.body.errorMessage);
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchCombined(
  auth: Auth,
  payload: String
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/combined?assemblycode=${payload}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      console.log(response.body.errorMessage);
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchComponentMapping(
  auth: Auth,
  base: string,
  selection: string
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/componentmapping?base=${base}&selection=${selection}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      console.log(response.body.errorMessage);
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}
