import React, { useState } from "react";

import { RouteProps } from "react-router-dom";
import { SignInForm } from "../components/SignInForm";
import ChangePassForm from "../components/ChangePassForm";
import { LogonFormWrapper } from "../components/LogonFormWrapper";
import { GlobalRefs } from "../store/refs";

interface Props extends RouteProps {
  globalrefs: React.MutableRefObject<GlobalRefs>;
  validateAuth: () => void;
}

interface Credentials {
  username: string;
  password: string;
}

interface State {
  errorMessage: string | undefined;
  resetPass: boolean;
}

const Signin: React.FC<Props> = ({ globalrefs, validateAuth }) => {
  const [{ errorMessage, resetPass }, setState] = useState<State>({
    errorMessage: undefined,
    resetPass: false,
  });

  const login = ({ username, password }: Credentials) => {
    globalrefs.current.auth.login(username, password).then((loginStatus) => {
      if (loginStatus.status === "new-password-required") {
        setState({ errorMessage: undefined, resetPass: true });
      } else if (loginStatus.status === "success") {
        validateAuth();
        window.sessionStorage.setItem("Scirebug-currentUser", username);
        return;
      } else {
        setState({ errorMessage: loginStatus.errorMessage, resetPass: false });
      }
    });
  };

  const changePass = async ({ newPassword }: { newPassword: string }) => {
    try {
      const loginResult = await globalrefs.current.auth.completePassword(
        newPassword
      );
      if (loginResult.success) {
        validateAuth();
        return;
      } else {
        setState({ resetPass: true, errorMessage: loginResult.errorMessage });
      }
    } catch (error) {
      console.error("Error changing password", error);
      setState({ resetPass: true, errorMessage: "Unknown error occured." });
    }
  };

  const defaultForm = (
    <LogonFormWrapper
      component={
        <SignInForm
          submitCallback={(cred: Credentials) => login(cred)}
          validationMessage={errorMessage}
        ></SignInForm>
      }
      header={"Platform Log In"}
    />
  );

  const resetPassForm = (
    <LogonFormWrapper
      component={
        <ChangePassForm
          submitCallback={(cred) => changePass(cred)}
          validationMessage={errorMessage}
        ></ChangePassForm>
      }
      header={"Password Reset"}
    />
  );

  return <>{resetPass ? resetPassForm : defaultForm}</>;
};

export default Signin;
