import { Admin, Controls, Order, MeshControl } from "../types/operationTypes";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export type State = {
  controls: Controls;
  order: Order;
  meshControl: MeshControl;
  gltf: GLTF | null;
  baseGltf: GLTF | null;
  baseMeshObject: string;
  mode: string;
  admin: Admin;
};

export type Action = {
  type: string;
  payload: any;
};

export const actionTypes = {
  SET_ADMIN: "SET_ADMIN",
  SET_BASEMESHOBJECT: "SET_BASEMESHOBJECT",
  SET_BASEGLTF: "SET_BASEGLTF",
  SET_CONTROLS: "SET_CONTROLS",
  SET_GLTF: "SET_GLTF",
  SET_ORDER: "SET_ORDER",
  SET_MESHCONTROL: "SET_MESHCONTROL",
  SET_MODE: "SET_MODE",
};
