import React, { useRef, useEffect, useCallback, useContext } from "react";
import { useLoader, useThree, useFrame } from "@react-three/fiber";
import { GlobalRefs } from "../store/refs";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { baseMeshMap } from "../data/maps";

interface Props {
  modelPath: string;
  scale: number;
  name: string;
  assembly: string;
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

const GltfAssembly: React.FC<Props> = ({
  modelPath,
  scale,
  name,
  assembly,
  globalrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { admin, baseMeshObject, controls, order, meshControl, mode, gltf } =
    store;

  const gltfx: GLTF = useLoader(GLTFLoader, modelPath);

  useEffect(() => {
    if (gltfx) {
      dispatch({ type: actionTypes.SET_GLTF, payload: gltfx });
    }
  }, [dispatch, gltfx]);

  const setColorOrder = useCallback(() => {
    if (gltf) {
      const selectedMesh: { [key: string]: {} } = {};
      Object.keys(order).forEach((code: string) => {
        Object.entries(order[code].selections)
          .filter((item: any) => {
            return item[0] === meshControl.name;
          })
          .forEach((entry: any) => {
            if (entry[1].base === baseMeshObject) {
              selectedMesh[entry[1].selectedMesh] = {};
            }
          });
      });

      gltf.scene.traverse((node: any) => {
        if (!node.isMesh) return;
        if (node.name in selectedMesh) {
          clonedMaterial.current = node.material.clone();
          node.material = clonedMaterial.current;
          node.material.color.set(globalrefs.current.defaultColours["click"]);
        } else if (node.name === admin.selected) {
          clonedMaterial.current = node.material.clone();
          node.material = clonedMaterial.current;
          node.material.color.set(
            globalrefs.current.defaultColours["candidate"]
          );
        } else {
          if (
            "truck" in globalrefs.current.originalMaterial &&
            globalrefs.current.parentMesh.assembly
          ) {
            node.material = globalrefs.current.originalMaterial["truck"];
          }
        }
      });
    }
  }, [admin.selected, baseMeshObject, meshControl, order, globalrefs, gltf]);

  useEffect(() => {
    setColorOrder();
  }, [order, assembly, globalrefs, meshControl, setColorOrder, mode]);

  const restorePosition = useCallback(
    (
      nodeName: string,
      clonedPosition: { [key: string]: number },
      key: string
    ) => {
      //restore position
      if (
        globalrefs.current.explodedOriginalPositionAssembly[
          meshControl.assembly
        ] &&
        globalrefs.current.explodedOriginalPositionAssembly[
          meshControl.assembly
        ][nodeName]
      ) {
        if (key === "a") {
          return {
            ...clonedPosition,
            x: globalrefs.current.explodedOriginalPositionAssembly[
              meshControl.assembly
            ][nodeName].x,
            y: globalrefs.current.explodedOriginalPositionAssembly[
              meshControl.assembly
            ][nodeName].y,
            z: globalrefs.current.explodedOriginalPositionAssembly[
              meshControl.assembly
            ][nodeName].z,
          };
        } else {
          return {
            ...clonedPosition,
            [key]:
              globalrefs.current.explodedOriginalPositionAssembly[
                meshControl.assembly
              ][nodeName][key],
          };
        }
      } else {
        return clonedPosition;
      }
    },
    [meshControl.assembly, globalrefs]
  );

  const savePosition = useCallback(
    (nodeName: string, clonedPosition: { [key: string]: number }) => {
      //save position
      if (
        globalrefs.current.explodedOriginalPositionAssembly[
          meshControl.assembly
        ]
      ) {
        if (
          globalrefs.current.explodedOriginalPositionAssembly[
            meshControl.assembly
          ][nodeName]
        ) {
          //do nothing
        } else {
          globalrefs.current.explodedOriginalPositionAssembly[
            meshControl.assembly
          ][nodeName] = { ...clonedPosition };
        }
      } else {
        globalrefs.current.explodedOriginalPositionAssembly[
          meshControl.assembly
        ] = {};
        globalrefs.current.explodedOriginalPositionAssembly[
          meshControl.assembly
        ][nodeName] = {
          ...clonedPosition,
        };
      }
    },
    [meshControl.assembly, globalrefs]
  );

  useEffect(() => {
    if (
      gltf &&
      controls &&
      controls.selectedExplodeButton.parent === "assembly"
    ) {
      gltf.scene.traverse((node: any) => {
        if (!node.isMesh) return;
        const pos = controls.selectedExplodeButton.button.assembly;
        const clonedPosition: { [key: string]: number } = node.position.clone();
        if (controls.explode.assembly[meshControl.assembly][pos]) {
          savePosition(node.name, clonedPosition);
          node.position.set(
            ...Object.values(
              pos === "a"
                ? {
                    ...clonedPosition,
                    x: clonedPosition.x * 1.5,
                    y: clonedPosition.y * 1.5,
                    z: clonedPosition.z * 1.5,
                  }
                : {
                    ...clonedPosition,
                    [pos]: clonedPosition[pos] * 1.5,
                  }
            )
          );
        } else {
          //restore position
          const newPosition = restorePosition(node.name, clonedPosition, pos);
          node.position.set(...Object.values(newPosition));
        }
      });
    }
  }, [
    gltf,
    meshControl.assembly,
    controls,
    restorePosition,
    savePosition,
    globalrefs,
  ]);

  const clonedMaterial = useRef();
  const intersected = useRef<string>("");

  const inspectIn = (e: any) => {
    intersected.current = e.intersections[0].object.name;
    if (gltf && mode === "") {
      gltf.scene.traverse((node: any) => {
        if (!node.isMesh) return;
        if (node.name === intersected.current) {
          clonedMaterial.current = node.material.clone();
          if ("truck" in globalrefs.current.originalMaterial) {
            //do nothing
          } else {
            globalrefs.current.originalMaterial["truck"] =
              node.material.clone();
          }

          node.material = clonedMaterial.current;
          node.material.color.set(globalrefs.current.defaultColours["hover"]);
        }
      });
    }
  };
  const inspectOut = (e: any) => {
    if (mode === "") {
      setColorOrder();
    }
  };

  const onClick = (e: any) => {
    dispatch({
      type: actionTypes.SET_ADMIN,
      payload: { ...admin, selected: intersected.current },
    });
    if (mode === "Admin") {
      setColorOrder();
      // dispatch({
      //   type: actionTypes.SET_ADMIN,
      //   payload: { ...admin, selected: intersected.current },
      // });
      //set to red
      if (gltf) {
        gltf.scene.traverse((node: any) => {
          if (!node.isMesh) return;
          if (node.name === intersected.current) {
            clonedMaterial.current = node.material.clone();
            if ("truck" in globalrefs.current.originalMaterial) {
              //do nothing
            } else {
              globalrefs.current.originalMaterial["truck"] =
                node.material.clone();
            }

            node.material = clonedMaterial.current;
            node.material.color.set(globalrefs.current.defaultColours["admin"]);
          }
        });
      }
    }
  };

  useThree(({ camera }) => {
    camera.position.y =
      globalrefs.current.cameraPosition.assembly[
        baseMeshMap[baseMeshObject].map[name].assembly
      ].y;
    camera.position.x =
      globalrefs.current.cameraPosition.assembly[
        baseMeshMap[baseMeshObject].map[name].assembly
      ].x;
    camera.position.z =
      globalrefs.current.cameraPosition.assembly[
        baseMeshMap[baseMeshObject].map[name].assembly
      ].z;
  });
  useFrame(({ camera, mouse }) => {
    globalrefs.current.cameraPosition.assembly[
      baseMeshMap[baseMeshObject].map[name].assembly
    ] = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    };
    // console.log(camera.position);
  });
  if (gltf) {
    return (
      <primitive
        // ref={globalrefs.current.currentGLTF}
        object={gltf.scene}
        scale={scale}
        onPointerOver={(event: any) => inspectIn(event)}
        onPointerOut={(event: any) => inspectOut(event)}
        onClick={(event: any) => onClick(event)}
      />
    );
  } else return null;
};

export default GltfAssembly;
