import Auth from "../services/auth";

const authServ = new Auth();

interface Storage {
  [key: string]: object;
}

export interface AllRefs {
  storage: Storage;
}

export const AllRefsDefault = {
  storage: { x: {} },
};
export interface GlobalRefs {
  auth: Auth;
  cameraPosition: {
    [key: string]: { [key: string]: { x: number; y: number; z: number } };
  };
  originalMaterial: { [key: string]: any };
  parentMesh: { name: string; assembly: string };
  defaultColours: { [key: string]: string };
  explodedOriginalPosition: {
    [baseModel: string]: {
      [assembly: string]: { [key: string]: number };
    };
  };
  explodedOriginalPositionAssembly: {
    [assembly: string]: {
      [component: string]: { [key: string]: number };
    };
  };
}

export const GlobalRefsDefault = {
  auth: authServ,
  cameraPosition: {
    baseModel: {
      truck: {
        x: 202.79439295265178,
        y: 125.00601963137221,
        z: -260.643494323568,
      },
      reefer: {
        x: 419.1696551191633,
        y: -190.65169871548161,
        z: 266.9212434431886,
      },
      trailer: {
        x: -97.25914606781744,
        y: 11.500842767134017,
        z: 1.5604787982436537,
      },
    },
    assembly: {
      Wheel: {
        x: 98.89775134793088,
        y: 60.96230801304488,
        z: -127.10931065084459,
      },
      FuelTank: {
        x: -32.82905500366347,
        y: 41.100302072776486,
        z: -5.862971942530377,
      },
      Reefer: {
        x: -229.78305331451094,
        y: 165.60008083841643,
        z: -328.2105864568299,
      },
      Container: {
        x: -74.34252581372647,
        y: 53.5771812015856,
        z: -106.18713453427814,
      },
      Landing_Gear: {
        x: 16.581150906227247,
        y: 9.573904312486667,
        z: -29.433948578504936,
      },
      "077-003": {
        x: 120.43649220673524,
        y: 50.79484430440493,
        z: 112.11101734938892,
      },
      "018-003": {
        x: 98.89775134793088,
        y: 60.96230801304488,
        z: -127.10931065084459,
      },
      Kingpin: {
        x: 6.586270556469926,
        y: 3.802891876468588,
        z: -11.691585824145081,
      },
    },
  },
  originalMaterial: {},
  parentMesh: { name: "", assembly: "" },
  defaultColours: {
    hover: "Orange",
    click: "#04aa6d",
    admin: "red",
    candidate: "brown",
  },
  // currentGLTF: null,
  explodedOriginalPosition: {},
  explodedOriginalPositionAssembly: {},
};
