import React, { useRef, useContext } from "react";
import {
  Layout,
  TabNode,
  TabSetNode,
  BorderNode,
  Model,
  Node,
} from "flexlayout-react";
import { GlobalRefs } from "../store/refs";
import { StoreContext } from "../store/store";
import { State } from "../store/storetypes";
import ModelViewer from "../components/ModelViewer";
import { BaseObjectSelector } from "../components/BaseObjectSelector";

import GltfModel from "../components/GltfModel";
import GltfAssembly from "../components/GltfAssembly";
import { OrderPanel } from "../components/OrderPanel";
import { Controls } from "../components/Controls";
import { Admin } from "../components/Admin";
import { Codes } from "../components/Codes";
import "flexlayout-react/style/light.css";
import { assemblyAttributes, baseMeshMap } from "../data/maps";
import "./layoutstyle.css";

interface Props {
  model: Model;
  globalrefs: React.MutableRefObject<GlobalRefs>;
}
interface ComponentSize {
  [key: string]: { width: number; height: number };
}

export const MeshLayouts: React.FC<Props> = ({ model, globalrefs }) => {
  const [store] = useContext(StoreContext) as [State];

  const { baseMeshObject, meshControl } = store;

  const componentSize = useRef<ComponentSize>({});

  const onRenderTabSet = (tabSetNode: TabSetNode | BorderNode) => {
    const typeNode = tabSetNode.getType();
    if (typeNode === "tabset") {
      const children = tabSetNode.getChildren();
      children.forEach((child: Node) => {
        const c = child as TabNode;
        const comp = c.getComponent();
        const rect = c.getRect();
        if (comp) {
          componentSize.current[comp] = {
            width: rect.width,
            height: rect.height,
          };
        }
      });
    }
  };

  const layoutModelChange = (model: Model) => {
    //console.log("model", JSON.stringify(model.toJson()));
  };
  const titleFactory = (node: TabNode) => {
    switch (node.getComponent()) {
      case "AssemblyModel":
        return meshControl.message;
      case "BaseObjectSelector":
        return baseMeshMap[baseMeshObject].displayName;
      case "BaseModel":
        return "";
      default:
        return;
    }
  };

  const factory = (node: TabNode) => {
    var component = node.getComponent();
    switch (component) {
      case "BaseModel":
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 4 }}>
              <Controls parent="base" />
            </div>
            <div style={{ flex: 20 }}>
              <ModelViewer>
                <GltfModel
                  modelPath={baseMeshMap[baseMeshObject].model}
                  scale={20}
                  name={baseMeshObject}
                  globalrefs={globalrefs}
                />
              </ModelViewer>
            </div>
          </div>
        );
      case "AssemblyModel":
        if (meshControl.active) {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1 }}>
                <Controls parent="assembly" />
              </div>
              <div style={{ flex: 10 }}>
                <ModelViewer>
                  <GltfAssembly
                    scale={20}
                    modelPath={
                      assemblyAttributes[
                        baseMeshMap[baseMeshObject].map[meshControl.name]
                          .assembly
                      ].model
                    }
                    name={meshControl.name}
                    assembly={meshControl.assembly}
                    globalrefs={globalrefs}
                  />
                </ModelViewer>
              </div>
            </div>
          );
        } else {
          return null;
        }
      case "OrderModel":
        if (meshControl.active) {
          return <OrderPanel globalrefs={globalrefs} />;
        } else {
          return null;
        }

      case "BaseObjectSelector":
        return <BaseObjectSelector globalrefs={globalrefs} />;
      case "Admin":
        return <Admin globalrefs={globalrefs} />;
      case "Codes":
        return <Codes globalrefs={globalrefs} />;

      default:
        return null;
    }
  };

  if (model) {
    return (
      <Layout
        model={model}
        factory={factory}
        onRenderTabSet={onRenderTabSet}
        onModelChange={layoutModelChange}
        titleFactory={titleFactory}
      />
    );
  } else return null;
};
