import { Model, IJsonModel } from "flexlayout-react";

const json: IJsonModel = {
  global: { tabEnableFloat: true },
  borders: [],
  layout: {
    type: "row",
    id: "#35b6f04e-1ee5-44d9-89e5-2e7b5bc87fb4",
    children: [
      {
        type: "row",
        id: "#70d0ac47-333b-475a-a7a9-795a24e9dce4",
        weight: 6.1295228569623434,
        children: [
          {
            type: "tabset",
            id: "#472a8e67-fa54-412e-872a-a4518859d502",
            weight: 17.59259259259259,
            children: [
              {
                type: "tab",
                id: "#263f76b6-870c-4879-9dd2-0f6e7ae037e2",
                name: "BaseObjectSelector",
                component: "BaseObjectSelector",
                enableClose: false,
                enableFloat: false,
              },
            ],
          },
          {
            type: "tabset",
            id: "#92dd264b-7b45-4820-ae2c-08721f84c576",
            weight: 82.4074074074074,
            children: [
              {
                type: "tab",
                id: "#f2d2e40d-3f26-49f5-98fc-da5a05d10406",
                name: "Trailer",
                component: "BaseModel",
                enableClose: false,
                enableFloat: false,
              },
            ],
            active: true,
          },
        ],
      },
      {
        type: "tabset",
        id: "#bfc976a1-6503-4be5-99ab-19159f5dba28",
        weight: 5.250057975189486,
        children: [
          {
            type: "tab",
            id: "#6b7c5aac-ee69-4203-ac37-50712ec2c791",
            name: "Assembly",
            component: "AssemblyModel",
            enableClose: false,
            enableFloat: false,
          },
        ],
      },
      {
        type: "tabset",
        id: "#33f20f74-aadf-4ebf-857d-2f7e58922680",
        weight: 3.466598790411741,
        children: [
          {
            type: "tab",
            id: "#27d36863-fb68-4bfc-ad9d-0cdc1265199a",
            name: "Codes",
            component: "Codes",
            enableClose: false,
            enableFloat: false,
          },
        ],
      },
      {
        type: "tabset",
        id: "#aa000b2d-fe2f-4981-b33a-157bb1ff3fda",
        weight: 3.9038203774364293,
        children: [
          {
            type: "tab",
            id: "#242e0168-d15d-4ff6-85e1-2dceb9894fa2",
            name: "Order",
            component: "OrderModel",
            enableClose: false,
            enableFloat: false,
          },
          {
            type: "tab",
            id: "#39e238d6-fde5-4fb7-804e-5b84823a7cf6",
            name: "Admin",
            component: "Admin",
            enableClose: false,
            enableFloat: false,
          },
        ],
      },
    ],
  },
};

export const model = Model.fromJson(json);
