import React, { Suspense } from "react";
import { ForwardCanvas } from "../store/store";
// import { useControls } from "leva";
import { OrbitControls, Html } from "@react-three/drei";
import { Lights } from "./Lights";

interface Props {
  children: React.ReactNode;
}

const ModelViewer: React.FC<Props> = ({ children }) => {
  const Fallback = () => (
    <Html>
      <div className="loading">Loading...</div>
    </Html>
  );
  return (
    <ForwardCanvas>
      <Lights />
      <Suspense fallback={<Fallback />}>
        {children}
        <OrbitControls />
      </Suspense>
    </ForwardCanvas>
  );
};

export default ModelViewer;
