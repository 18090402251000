import React from "react";
import { GlobalRefs } from "../store/refs";

import { MeshLayouts } from "../layouts/MeshLayouts";
import { model } from "../layouts/LayoutModel";

interface Props {
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

export const Home: React.FC<Props> = ({ globalrefs }) => {
  const getModel = () => {
    return model;
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MeshLayouts model={getModel()} globalrefs={globalrefs} />
    </div>
  );
};
