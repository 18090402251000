import React, { useContext } from "react";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { GlobalRefs } from "../store/refs";
// import { getFileDownloadUrl } from "../api/Scirebug";
import Auth from "../services/auth";
import AWS from "aws-sdk";
import { writeCombined } from "../api/Scirebug";

AWS.config.update({
  accessKeyId: "AKIARN4PPYDSI47GGEV2",
  secretAccessKey: "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",
  region: "eu-west-2",
});

const buttonStyle = {
  border: "1px solid black",
  borderRadius: 4,
  marginTop: 5,
};

interface Props {
  globalrefs: React.MutableRefObject<GlobalRefs>;
}

const chunkArrayInGroups = (arr: any[], size: number) => {
  let myArray = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
};

const WriteCombinedAsync = async (items: {}[], auth: Auth) => {
  let x = 33400;
  let chunk = 25;
  let from = 0;

  for (let i = from; i <= x; i += chunk) {
    if (i > 0) {
      const fromx = from;

      const itemsToInsert = items.filter((item, j: number) => {
        return j < i && j >= fromx;
      });

      const ret = await writeCombined(auth, itemsToInsert);

      console.log("ret", ret);
      console.log("from >=", from, "to <", i);
      from = i;
      // }
    }
  }
  console.log("from >=", from, "to <", x);
  console.log("test new token");

  const itemsToInsert = items.filter((item, j) => {
    return j < x && j >= from;
  });
  const ret = await writeCombined(auth, itemsToInsert);
  console.log("ret2", ret);
};

export const Admin: React.FC<Props> = ({ globalrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { mode, admin } = store;

  const onLoad = () => {
    const s3 = new AWS.S3();
    const params = { Bucket: "scirebug-wip", Key: "combined.csv" };
    // var s3file = s3.getObject(params);

    s3.getObject(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        if (data && data.Body) {
          const dataS = data.Body.toString().split("\r\n").join("|");
          const dataArray = dataS.split("|");
          const chunkeddataArray = chunkArrayInGroups(dataArray, 9);
          const items = chunkeddataArray.map((item) => {
            return {
              code: item[0],
              Assembly_Code: item[1],
              Component: item[2],
              Assembly: item[3],
              System: item[4],
              System_key: item[5],
              Assembly_key: item[6],
              Component_key: item[7],
              obsolete: item[8],
            };
          });

          WriteCombinedAsync(items, globalrefs.current.auth);
        } else {
          console.log(data); // successful response
        }
      }
    });
  };
  const adminMode = () => {
    if (mode === "Admin") {
      dispatch({
        type: actionTypes.SET_ADMIN,
        payload: { ...admin, selected: "" },
      });
    }
    dispatch({
      type: actionTypes.SET_MODE,
      payload: mode === "Admin" ? "" : "Admin",
    });
  };

  // const downloadFile = async (auth: Auth, filepath: string) => {
  //   const url = await getFileDownloadUrl(auth, filepath, "fileurl");

  //   window.open(url, "_blank");
  // };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          height: "30",
          margin: 30,
        }}
      >
        <button disabled style={buttonStyle} onClick={() => onLoad()}>
          Load FROM S3
        </button>
        <button
          style={buttonStyle}
          onClick={() => globalrefs.current.auth.logout()}
        >
          Log Out
        </button>
        <button
          style={{
            ...buttonStyle,
            border: mode === "Admin" ? "3px solid black" : "1px solid black",
          }}
          onClick={() => adminMode()}
        >
          Admin Mode
        </button>
      </div>
    </div>
  );
};
